import * as config from '../config/config.json';
import { Title } from '@angular/platform-browser';
import { ITenantMetaData } from './interfaces/tenant-meta.interface';
import { ITenant } from './interfaces/tenant.interface';
import { ProductService } from './services/product.service';
import { TenantMetaService } from './services/tenant-meta.service';

export function initializer(
  tenantMetaService: TenantMetaService,
  productService: ProductService,
  titleService: Title
): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        productService.getTenant().subscribe((tenant: ITenant) => {
          tenantMetaService.setTenant(tenant.name);
          tenantMetaService.getTenantMetaData(config.languages[0]).subscribe((response: ITenantMetaData) => {
            if (response.company) {
              titleService.setTitle(`${response.company}`);
            } else {
              titleService.setTitle(`Docs`);
            }
            if (response.theme) {
              tenantMetaService.setTheme(response.theme);
              resolve();
            } else {
              reject();
            }
          });
        });
      } catch (error) {
        reject(error);
      }
    });
  };
}
