import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as config from '../config/config.json';
import { TranslateService } from '@ngx-translate/core';
import { ITenantMetaData } from './interfaces/tenant-meta.interface';
import { TenantMetaService } from './services/tenant-meta.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  public languages: string[] = config.languages;
  public currentLanguage: string = this.languages[0];

  public legal;
  public LEGAL_TYPE_LINK = 'LINK';
  public LEGAL_TYPE_HTML = 'HTML';
  public companyString: string;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly tenantMetaService: TenantMetaService,
    private readonly translateService: TranslateService
  ) { }


  public updateLanguage(language: string) {
    this.router.navigateByUrl(
      this.router.url.replace(this.currentLanguage, language)
    );
  }

  private updateLegal(language: string) {
    this.tenantMetaService.getTenantMetaData(language).subscribe((data: ITenantMetaData) => {
      if (data.legal) {
        this.legal = data.legal;
      }
      if (data.company) {
        this.companyString = `© ${new Date().getFullYear()} ${data.company}`;
      }
    });
  }

  ngOnInit() {
    this.translateService.use(this.currentLanguage);
    this.updateLegal(this.currentLanguage);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {

        const activeRouterChildWithLanguageParam = this.activatedRoute.children.find(
          (child: any) => child.params && child.params.value && child.params.value['language']
        ) as any;

        if (activeRouterChildWithLanguageParam.params &&
          activeRouterChildWithLanguageParam.params.value &&
          activeRouterChildWithLanguageParam.params.value['language']
        ) {
          this.currentLanguage = activeRouterChildWithLanguageParam.params.value['language'];
        }

        if (this.languages.indexOf(this.currentLanguage) !== -1) {
          this.translateService.use(this.currentLanguage);
          this.updateLegal(this.currentLanguage);
        } else {
          this.router.navigate(['/']);
        }
      }
    });
  }

}
