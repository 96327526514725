import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IDocHtmlMenuItem } from '../../interfaces/doc.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-doc-nav-tree',
  templateUrl: './product-doc-nav-tree.component.html'
})

export class ProductDocNavTreeComponent implements OnInit, OnChanges {
  @Input() docMenuTree: IDocHtmlMenuItem[] = [];
  @Input() activeDoc = '';

  public triggerExtended(event, item: IDocHtmlMenuItem) {
    event.stopPropagation();
    event.preventDefault();
    item.extended = !item.extended;
  }

  private isSubDocumentActive(menu: IDocHtmlMenuItem[]) {
    let state = false;
    if (menu && menu.length > 0) {
      menu.forEach(item => {
        if (!state) {
          if (item.id === this.activeDoc) {
            state = true;
          } else if (item.children && item.children.length > 0) {
            state = this.isSubDocumentActive(item.children);
          }
        }
      });
    }
    return state;
  }

  private setExtendedProperty() {
    if (this.docMenuTree && this.docMenuTree.length > 0) {
      this.docMenuTree.map(item => {
        item.extended = item.id === this.activeDoc ? true : this.isSubDocumentActive(item.children);
        return item;
      });
    }
  }

  ngOnInit(): void {
    this.setExtendedProperty();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeDoc
      && changes.activeDoc.previousValue
      && changes.activeDoc.previousValue !== changes.activeDoc.currentValue) {
      this.setExtendedProperty();
    }
  }

}
