import { Inject, Injectable } from '@angular/core';
import * as config from '../../config/config.json';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { ITenantMetaData } from '../interfaces/tenant-meta.interface';

@Injectable()
export class TenantMetaService {

  private tenant: string;

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private readonly http: HttpClient
  ) { }

  public api = `${config.tenantManagerUrl}/api/meta`;

  public setTenant(tenant: string) {
    this.tenant = tenant;
  }

  public getTenantMetaData(language): Observable<ITenantMetaData> {
    return this.http.get<ITenantMetaData>(`${this.api}/${this.tenant}?locale=${language}`);
  }

  public setTheme(theme) {
    this._document.getElementById('favicon').setAttribute('href', theme.faviconUri);
    this._document.getElementById('theme').setAttribute('href', theme.cssMinUri);
  }

}
