import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IDocHtml, IDocHtmlMenuItem, IDocPdf } from '../interfaces/doc.interface';
import { ProductService } from '../services/product.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit, OnDestroy {

  private navigationSubscription: Subscription;

  private language: string;
  private productId: string;

  public productName = '';

  public htmlDocuments: IDocHtml[] = [];
  public hasDownloads = false;

  public activeDoc: string;
  public docContent: SafeHtml;

  public loadingMenu = true;
  public loadingDocContent = false;

  public hasData = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly location: Location,
    private readonly http: HttpClient,
    private readonly sanitizer: DomSanitizer,
    private readonly productService: ProductService
  ) { }

  private isPdfDoc(documents: IDocHtml | IDocPdf): boolean {
    return documents.type === 'manual' || documents.type === 'supplierDocument';
  }

  private findMenuElementByIdRecursive(id: string, menu: IDocHtmlMenuItem[]): IDocHtmlMenuItem | null {
    let result: IDocHtmlMenuItem | null = null;
    menu.forEach(item => {
      if (item.id === id) {
        result = item;
      } else if (item.children) {
        const childResult = this.findMenuElementByIdRecursive(id, item.children);
        if (childResult) {
          result = childResult;
        }
      }
    });
    return result;
  }
  private preventDocLinks(language, productId) {
    document.querySelectorAll('.doc-content a').forEach(element => {
      const href = element.getAttribute('href');
      let id;
      if (href.indexOf('/') !== -1) {
        const split = href.split('/');
        id = split[split.length - 1];
      } else {
        const split = href.split('#');
        id = split[split.length - 1];
      }
      element.setAttribute('href', `#/${language}/product/${productId}/${id}`);
    });
  }

  private getConcatMenus() {
    let concatMenus: IDocHtmlMenuItem[] = [];
    this.htmlDocuments.forEach(item => {
      concatMenus =  concatMenus.concat(item.menu);
    });
    return concatMenus;
  }

  private setActiveDoc() {
    this.loadingDocContent = true;
    if (this.activatedRoute.snapshot.params['docId']) {
      this.activeDoc = this.activatedRoute.snapshot.params['docId'];
      const concatMenus = this.getConcatMenus();
      const doc = this.findMenuElementByIdRecursive(this.activeDoc, concatMenus);
      this.http.get(doc.url, {responseType: 'text'}).subscribe((content: string) => {
        this.docContent = this.sanitizer.bypassSecurityTrustHtml(content);
        this.loadingDocContent = false;
        setTimeout(() => {
          this.preventDocLinks(this.language, this.productId);
        }, 250);
      });
    } else if (this.hasData){
      const currentDocId = this.htmlDocuments[0].menu[0].id;
      this.router.navigateByUrl(`/${this.language}/product/${this.productId}/${currentDocId}`);
    } else {
      this.loadingDocContent = false;
    }
  }

  private setProductName() {
    this.productService.getProductName(this.productId).subscribe(productName => {
      this.productName = productName;
    });
  }

  private setDocuments() {
    this.loadingMenu = true;
    this.activeDoc = '';
    this.docContent = this.sanitizer.bypassSecurityTrustHtml('');
    if (this.productId && this.language) {
      this.productService.getProductLanguageId(this.productId, this.language).subscribe(languageId => {
        if (languageId) {
          this.productService.getProductDocs(this.productId, languageId).subscribe(documents => {
            const htmlDocuments = documents.filter(doc => doc.type === 'timHtml') as IDocHtml[];

            this.htmlDocuments = htmlDocuments
              .sort((a, b) => a.name.localeCompare(b.name));

            this.hasDownloads = documents.some(doc => this.isPdfDoc(doc));
            this.loadingMenu = false;
            this.hasData = !!this.htmlDocuments[0];
            if (this.hasData) {
              if (!!this.htmlDocuments[0].menu) {
                this.setActiveDoc();
              } else {
                this.router.navigateByUrl(`/${this.language}/product/${this.productId}`);
              }
            } else {
              this.router.navigateByUrl(`/${this.language}/product/${this.productId}`);
            }
          });
        } else {
          this.router.navigateByUrl(`/${this.language}/product/${this.productId}`);
          this.htmlDocuments = [];
          this.activeDoc = null;
          this.hasDownloads = false;
          this.hasData = false;
          this.loadingMenu = false;
          this.loadingDocContent = false;
        }
      });
    } else {
      this.htmlDocuments = [];
      this.activeDoc = null;
      this.hasDownloads = false;
      this.hasData = false;
      this.loadingMenu = false;
    }
  }

  private hasLanguageChanged() {
    return this.language !== this.activatedRoute.snapshot.params['language'];
  }

  private hasDocChanged() {
    return this.activeDoc !== this.activatedRoute.snapshot.params['docId'];
  }

  ngOnInit() {
    this.language = this.activatedRoute.snapshot.params['language'];
    this.productId = this.activatedRoute.snapshot.params['productId'];
    this.setDocuments();
    this.setProductName();
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.hasLanguageChanged()) {
          this.language = this.activatedRoute.snapshot.params['language'];
          this.setDocuments();
        } else if (this.hasDocChanged()) {
          this.setActiveDoc();
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.navigationSubscription && this.navigationSubscription.unsubscribe) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
