import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as config from '../../config/config.json';
import { Observable } from 'rxjs/index';
import { ITenant } from '../interfaces/tenant.interface';
import { IProduct } from '../interfaces/product.interface';
import { ILanguage } from '../interfaces/language.interface';
import { IDocHtml, IDocPdf } from '../interfaces/doc.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private tenantId: string = config.tenantId;

  constructor(private readonly firestore: AngularFirestore) {}

  public getTenant(): Observable<ITenant> {
    return this.firestore.doc<ITenant>(`tenants/${this.tenantId}`).valueChanges();
  }

  public getProductName(productId: string) {
    return this.firestore
      .collection<ILanguage>(`tenants/${this.tenantId}/products`)
      .valueChanges({idField: 'id'})
      .pipe(
        map(products => {
          let productName = '';
          products.forEach(product => {
            if (product.id && product.name && product.id === productId) {
              productName = product.name;
            }
          });
          return productName;
        })
      );

  }

  public getProducts(): Observable<IProduct[]> {
    return this.firestore
      .collection<IProduct>(`tenants/${this.tenantId}/products`)
      .valueChanges({idField: 'id'});
  }

  public getProductLanguageId(productId: string, name: string): Observable<string | null> {
    return this.firestore
      .collection<ILanguage>(
        `tenants/${this.tenantId}/products/${productId}/languages`,
        language => language.where('name', '==', name)
      )
      .valueChanges({idField: 'id'})
      .pipe(
        map(languages => languages[0] && languages[0].id ? languages[0].id : null)
      );
  }

  public getProductDocs(productId: string, languageId: string): Observable<Array<IDocHtml | IDocPdf>> {
    return this.firestore
      .collection<IDocHtml | IDocPdf>(`tenants/${this.tenantId}/products/${productId}/languages/${languageId}/docs`)
      .valueChanges({idField: 'id'});
  }

}
