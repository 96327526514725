import { Routes } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { LegalComponent } from './legal/legal.component';
import { ProductComponent } from './product/product.component';

import * as config from '../config/config.json';
import { DownloadsComponent } from './downloads/downloads.component';

export const getStartPath = () => {
  return  config &&  config.languages &&  config.languages[0] ?  config.languages[0] : '';
};

export const AppRoutes: Routes = [
  {
    path: ':language/welcome',
    component: WelcomeComponent
  }, {
    path: ':language/legal/:legalType',
    component: LegalComponent
  }, {
    path: ':language/product/:productId',
    component: ProductComponent
  }, {
    path: ':language/product/:productId/:docId',
    component: ProductComponent
  }, {
    path: ':language/downloads/:productId',
    component: DownloadsComponent
  }, {
    path: '**',
    redirectTo: `${getStartPath()}/welcome`,
    pathMatch: 'full'
  }
];
