import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductComponent } from './product.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { ProductDocNavTreeComponent } from './product-doc-nav-tree/product-doc-nav-tree.component';

@NgModule({
  declarations: [ProductComponent, ProductDocNavTreeComponent],
  imports: [
    CommonModule,
    TranslateModule,
    HttpClientModule,
    RouterModule
  ]
})
export class ProductModule { }
