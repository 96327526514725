import { Component, OnInit } from '@angular/core';
import { ProductService } from '../services/product.service';
import { IProduct } from '../interfaces/product.interface';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  public products: IProduct[] = [];

  public hasData = false;
  public loading = true;

  constructor(private readonly productService: ProductService) { }

  private getProducts() {
    this.loading = true;
    this.productService.getProducts().subscribe((products) => {
      this.products = products;

      this.loading = false;
      this.hasData = this.products.length > 0;
    });
  }

  ngOnInit() {
    this.getProducts();
  }
}
