import { Component, OnInit } from '@angular/core';
import { IDocPdf } from '../interfaces/doc.interface';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.css']
})
export class DownloadsComponent implements OnInit {

  private language: string;
  private productId: string;

  private productName = '';

  public hasData = false;
  public loading = true;

  public manuals: IDocPdf[] = [];
  public supplierDocuments: IDocPdf[] = [];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly productService: ProductService
  ) {}

  private setProductName() {
    this.productService.getProductName(this.productId).subscribe(productName => {
      this.productName = productName;
    });
  }

  private setDocuments() {
    this.loading = true;
    this.productService.getProductLanguageId(this.productId, this.language).subscribe(languageId => {
      this.productService.getProductDocs(this.productId, languageId).subscribe(documents => {
        this.manuals = documents
          .filter(d => d.type === 'manual')
          .sort((a, b) => b.name.localeCompare(a.name)) as IDocPdf[];
        this.supplierDocuments = documents
          .filter(d => d.type === 'supplierDocument')
          .sort((a, b) => a.name.localeCompare(b.name)) as IDocPdf[];

        this.hasData = this.manuals.length > 0 || this.supplierDocuments.length > 0;
        this.loading = false;
      });
    });
  }

  ngOnInit() {
    this.language = this.activatedRoute.snapshot.params['language'];
    this.productId = this.activatedRoute.snapshot.params['productId'];
    this.setProductName();
    this.setDocuments();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.language = this.activatedRoute.snapshot.params['language'];
        this.productId = this.activatedRoute.snapshot.params['productId'];
        this.setProductName();
        this.setDocuments();
      }
    });
  }
}
