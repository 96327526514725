import { Component, OnInit } from '@angular/core';
import { TenantMetaService } from '../services/tenant-meta.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ITenantMetaData } from '../interfaces/tenant-meta.interface';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.css']
})
export class LegalComponent implements OnInit {

  private legalType: string;
  private language: string;

  public companyString: string;
  public legalContent: string;

  public loading = true;

  constructor(
    private tenantMetaService: TenantMetaService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  private getLegalData() {
    this.loading = true;
    this.tenantMetaService.getTenantMetaData(this.language).subscribe((data: ITenantMetaData) => {
      if (data.legal && data.legal[this.legalType] && data.legal[this.legalType].value) {
        this.legalContent = data.legal[this.legalType].value;
      }
      if (data.company) {
        this.companyString = `© ${new Date().getFullYear()} ${data.company}`;
      }
      this.loading = false;
    });
  }

  ngOnInit() {
    this.legalType =  this.activatedRoute.snapshot.params['legalType'];
    this.language = this.activatedRoute.snapshot.params['language'];
    this.getLegalData();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.legalType =  this.activatedRoute.snapshot.params['legalType'];
        this.language = this.activatedRoute.snapshot.params['language'];
        this.getLegalData();
      }
    });
  }

}
